import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { SmallCta, ParentsTestimonial } from "../components/sections"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      huset: file(relativePath: { eq: "content/brandilen-huset.jpg" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      draken: file(relativePath: { eq: "content/draken-no-ng-bigger.png" }) {
        childImageSharp {
          fixed(width: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Brandbilen firar 30 år" description="Brandbilen fyller 30 år. Tack alla barn. föräldrar och personal som under åren varit med på denna resa. Det är bara början på en fantastisk fortsättning, väl värd att fira." />
      <Layout>
        <div className="py-16 bg-warm-grey-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
                Brandbilen fyller 30 år
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-warm-grey-500">
                Tack alla barn. föräldrar och personal som under åren varit med på denna resa. Det är bara början på en fantastisk fortsättning, väl värd att fira.
              </p>
            </div>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-extrabold text-warm-grey-900 tracking-tight sm:text-3xl sm:leading-9">
                  Vidare
                </h4>
                <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                  Daghemmet Brandbilen registrerades tidigt på våren 1990. Arbetet med att anställa personal och hitta en lämplig lokal fortskred under året och valet blev scoutlokalen i Gullänget. Redan 1991 startade verksamheten i samma lokal som det bedrivs i idag.
                </p>
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          1
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Föräldrakooperativ</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Förskolan Brandbilen är numera namnet på det föräldrakooperativ som föräldrar tillsammans med personalen driver enligt läroplan, Lpfö 18, där stor vikt läggs till barnens utveckling.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          2
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Två små barngrupper</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Här finns två små barngrupper elva barn vardera, som får stöd och kan utvecklas i grupp och enskilt.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          3
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Transparens och delaktighet</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Då förskolan är ett föräldrakooperativ så är verksamheten transparent och föräldrarna får vara delaktiga i barnens utveckling.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          4
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Att vara utomhus</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Det finns inget dåligt väder, bara dåliga kläder. Det är Brandbilens paroll och därför tillbringas stor del av dagen ute. Det är till och med vanligt att den hemlagade lunchen intas ute i det fria.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 flex">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                </svg>
                <Img className="relative mx-auto" fixed={data.huset.childImageSharp.fixed} />
              </div>
            </div>

            <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <SmallCta />
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 flex">
                  <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                    <defs>
                      <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                  </svg>
                  <Img className="relative mx-auto" fixed={data.draken.childImageSharp.fixed} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ParentsTestimonial />
      </Layout>
    </>
  )
}



export default Page
